import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../containers/SaasModern/Documentation';
import data_source_appearance from '../../../../../../common/src/assets/image/data_source_appearance.png';
import calendar_data_sources_add from '../../../../../../common/src/assets/image/calendar_data_sources_add.png';
import calendar_preset_save from '../../../../../../common/src/assets/image/calendar_preset_save.png';
import calendar_preset_select from '../../../../../../common/src/assets/image/calendar_preset_select.png';
import calendar_data_sources_color from '../../../../../../common/src/assets/image/calendar_data_sources_color.png';
import filter_none from '../../../../../../common/src/assets/image/filter_none.png';
import filter_categories_and_all_day from '../../../../../../common/src/assets/image/filter_categories_and_all_day.png';
import filter_titles from '../../../../../../common/src/assets/image/filter_titles.png';

const Documentation = () => {
  const content = (
    <div>
      <div>
        <p>
          PrintableCal can import events and tasks from a variety of data
          sources. The latest events, tasks, and notes from the selected data
          sources will be automatically imported each time a calendar is
          generated using the{' '}
          <Link to="/Documentation/Create-Calendar">Create Calendar</Link>{' '}
          command on the PrintableCal tab of the Excel, Word, or PowerPoint
          ribbon menu. Events, tasks, and notes will be color-coded based on the
          associated calendar or category. A{' '}
          <Link to="/Documentation/Insert-Legend">
            legend can also be inserted
          </Link>
          .
        </p>
        <p>
          <Image alt="" src={data_source_appearance} style={{ width: 457 }} />
        </p>
        <h2>Add Calendar Data Source</h2>
        <p>
          To add a calendar data source, click the <strong>Add Calendar</strong>{' '}
          button above the list and then select the desired data source type to
          add from the <strong>Online</strong> or <strong>Local</strong>{' '}
          sub-menus.{' '}
        </p>
        <p>
          <Image
            alt=""
            src={calendar_data_sources_add}
            style={{ width: 451 }}
          />
        </p>
        <p>The following online data sources are supported:</p>
        <ul>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/CSV-File">
              CSV File
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Excel-File">
              Excel File
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Exchange-Server">
              Exchange Server
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Facebook">
              Facebook
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Google-Calendar">
              Google Calendar
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Google-Tasks">
              Google Tasks
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Holidays-Sports-More">
              Holidays, Sports, More
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/iCalendar-File">
              iCalendar File
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/iCalendar-URL">
              iCalendar URL
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/iCloud">
              iCloud
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Microsoft365">
              Microsoft 365 / Office 365
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Outlook-Local">
              Outlook (local)
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Outlook-Tasks-Local">
              Outlook Tasks (local)
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Outlookcom">
              Outlook.com
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Remember-The-Milk">
              Remember The Milk
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Todoist">
              Todoist
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Toodledo">
              Toodledo
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/VueMinder">
              VueMinder
            </Link>
          </li>
          <li>
            <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Yahoo-Calendar">
              Yahoo! Calendar
            </Link>
          </li>
        </ul>
        <h2>Custom Calendar Data Sources</h2>
        <p>
          PrintableCal can be customized to connect to almost any calendar data
          source. For example, if your schedule is in a database, PrintableCal
          can pull events from your database and include those events in the
          generated calendar output. If you have a calendar data source that
          isn't currently supported in PrintableCal, please{' '}
          <Link to="/#content_us_section">contact us</Link> with details. We can
          customize PrintableCal to meet your needs.
        </p>
        <h2>Selecting Calendar Data Sources</h2>
        <p>
          After a calendar data source has been added, it can be selected for
          inclusion in the generated calendar by checking the box to the left of
          the calendar data source's name. A data source that is unchecked will
          not be included in the output, but will still be available later
          (without needing to re-add the data source) if you'd like to include
          it. To completely remove a data source, click the data source and then
          click the <strong>X</strong> button to the right of the data source's
          name.
        </p>
        <h2>Color-Coding Data Sources</h2>
        <p>
          Data from all selected sources will be overlaid into the same
          generated calendar and color-coded based on the calendar data source.
          To view the settings, click a data source's name in the list. A
          settings panel will appear below the calendar's name. This panel will
          also automatically appear after adding a new calendar data source.
        </p>
        <p>
          <Image
            alt=""
            src={calendar_data_sources_color}
            style={{ width: 435 }}
          />
        </p>
        <table className="table">
          <tbody>
            <tr>
              <td>
                <strong>Item prefix </strong>
              </td>
              <td>
                <span>
                  An optional prefix can also be specified for each data source.
                  Events and tasks associated with the data source will include
                  the specified prefix in their titles.{' '}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Font </strong>
              </td>
              <td>
                The font for each data source can be selected from a drop-down
                list. The font size can also be selected. This will be the font
                used for events and tasks that are associated with the calendar
                data source. Some templates provide an option to override the
                default font size specified here.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Text Style</strong>
              </td>
              <td>
                The font for each data source can be made <strong>bold</strong>,{' '}
                <em>italic</em>, and{' '}
                <span style={{ textDecoration: 'underline' }}>underlined</span>{' '}
                by toggling the boxes. Text can also be left, center, or right
                aligned (depending on the template being used). A text color can
                also be selected. Some templates, such as the Month template,
                might use the specified
                <strong>Border</strong> color for event and task text for items
                that are not all-day.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Background </strong>
              </td>
              <td>
                Events and tasks associated with the calendar data source will
                be color-coded based on the specified background color
                (depending on the template being used). If you want to save on
                ink or toner, you can select <strong>Transparent </strong>from
                the color drop-down. You can also click{' '}
                <strong>More Colors</strong> to define a custom color.
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Border</strong>
              </td>
              <td>
                A border color and thickness can be specified. Using a different
                border color can help further differentiate items in the
                generated output. Depending on the template, the specified
                border color might also be used as the text color for some
                items.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2>Filtering</h2>
      <p>
        PrintableCal also provides the ability to perform additional filtering
        on the items that appear in the output.{' '}
          The filtering criteria is specified below the list of calendar data
          sources. Items can be filtered if they are associated with a specified
        categories, if they have certain word/phrases in their titles, if they are marked as "all-day", or if they have start/end
        times.
      </p>
      <p>
        Any specified filter will be the default the next time a calendar is
        generated. If a filter is applied, a red border will appear around the
        filter box to remind you that some items might not appear in the output.
      </p>
      <p>
        <Image alt="" src={filter_none} style={{ width: 448 }} />
      </p>
      <h3>Filtering Categories</h3>
      <p>
        To filter the output so only items that are associated with specified categories are included or excluded, select <strong>Include only</strong> or <strong>Exclude only</strong> options and enter the desired category names.
        Category names are defined in the <Link to="/Documentation/Options/Category-Colors">PrintableCal Options</Link> When <strong>Include only</strong> is selected, only items that are associated with at least
        one of the specified categories will be included in the output. All other items will be excluded. Likewise, when <strong>Exclude only</strong> is selected, only items associated with at least one of the
        specified categories will be excluded from the output. Multiple categories can be specified while typing by entering a semi-colon, pressing the Enter key, or typing and select each desired category from the drop-down
        list. To remove a filtered category, click the X button to the right of its name.
        <Image
          alt=""
          src={filter_categories_and_all_day}
          style={{ width: 450 }}
        />
      </p>
      <h3>Filtering Titles</h3>
      <p>
        To filter the output so only items that have the specified words or phrases in their titles are included or excluded, select <strong>Include only</strong> or <strong>Exclude only</strong> options and enter the desired word or phrases.
        When <strong>Include only</strong> is selected, only items with titles containing at least
        one of the specified words/phrases will be included in the output. All other items will be excluded. Likewise, when <strong>Exclude only</strong> is selected, only items with titles containing at least of the
        specified words/phrases will be excluded from the output. Multiple words/phrases can be specified while typing by entering a semi-colon or pressing the Enter key. To remove a filtered word or phrase, click the X button to the right of its name.
        <Image
        alt=""
        src={filter_titles}
        style={{ width: 450 }}
        />
      </p>
      <h2>Defining Presets</h2>
      <p>
        You can define and re-use preset combinations of selected calendar
        data sources and filters when generating calendars or{' '}
        <Link to="/Documentation/Insert-Legend">legends</Link>. PrintableCal
        will remember which calendar data sources and filters were most recently selected,
        but if you want to use different combinations of calendar data sources
        and filters when generating with different templates, defining presets will
        eliminate the need to manually select the individual
        calendar data sources and filter settings each time.
      </p>
      <p>
        To save a preset, first select the calendar data sources you'd like to
        include in the preset by using the check boxes next to the calendar
        names. After selecting the desired calendars, enter a name for the
        preset and click the Save button.
      </p>
      <p>
        <Image alt="" src={calendar_preset_save} style={{ width: 449 }} />
      </p>
      <p>
        You can save any number of presets. To select a previously defined
        preset, type its name in the "Preset" field, or select from the
        drop-down list. The checked calendar data sources and filter settings will update to match
        what was previously saved in the preset.
      </p>
      <p>
        <Image alt="" src={calendar_preset_select} style={{ width: 456 }} />
      </p>
      <p>
        You can also modify a previously defined preset by selecting the
        desired calendars or filter settings and clicking the Save button. If you no longer need
        a preset, it can be removed by clicking the Delete button. This won't
        remove the calendar data sources associated with the preset.
      </p>
    </div>
  );
  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources"
      commentsId="commentsplus_post_134_489"
      title="Step 3: Select Calendar Data Sources"
      description="PrintableCal can import events and tasks from Exchange Server, Google Calendar, Outlook, ICAL, Remember the Milk, Toodledo, Yahoo! Calendar, VueMinder, Facebook, and more."
      keywords="printable calendar, import events, Exchange Server, Google Calendar, Outlook, ICAL, iCalendar, Remember the Milk, Toodledo, Yahoo! Calendar, VueMinder"
      content={content}
    />
  );
};

export default Documentation;
